<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { PhFunnelSimple } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';
import { SortValueToLabelMap } from './utils';
import type { FilterSchema } from '@laam/cms-shared';
import { isMobileView } from '~/utils/helpers';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const field = useField<FilterSchema['sort_by']>('sort_by');

provide('selectedFilter', 'sort');
</script>
<template>
	<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
		<Button size="xs" variant="tertiary" class="sort_filter_drawer_trigger">
			<template #leftIcon>
				<PhFunnelSimple :weight="isMobileView() ? 'bold' : 'regular'" />
			</template>
			<span class="lg:px-xxs">
				{{
					field.value.value
						? SortValueToLabelMap[
								field.value.value as keyof typeof SortValueToLabelMap
							]
						: 'Sort'
				}}
			</span>
		</Button>
	</FilterDrawer>

	<FilterDialog v-else @reset-form="() => emits('reset-form')">
		<Button
			size="md"
			variant="secondary"
			class="sort_filter_dropdown_trigger w-fit"
		>
			<template #leftIcon>
				<PhFunnelSimple :weight="'regular'" />
			</template>
			<span class="lg:px-xxs">
				{{
					field.value.value
						? SortValueToLabelMap[
								field.value.value as keyof typeof SortValueToLabelMap
							]
						: 'Sort'
				}}
			</span>
		</Button>
	</FilterDialog>
</template>
