import { z } from 'zod';
import {
	octaneFooterSchema,
	headerFormSchema,
	heroBannerFormSchema,
	entityXProductsFormSchema,
	entityFormSchema,
	linkNodeSchema,
	dynamicInputSchema,
	staticInputSchema,
	productFormSchema,
	heroBannerItemSchema,
	timerSchema,
	staticInputFormSchema,
	filterSchema,
	miniBannerFormSchema,
} from './zod';

export type OctaneHeaderData = z.infer<typeof headerFormSchema>;
export type NavMenuItem = OctaneHeaderData['menubar'][number];
export type LinkNode = z.infer<typeof linkNodeSchema>;
export type DynamicInputData = z.infer<typeof dynamicInputSchema>;
export type StaticInputData = z.infer<typeof staticInputSchema>;
export type StaticInputFormSchema = z.infer<typeof staticInputFormSchema>;

export type EntityData = z.infer<typeof entityFormSchema>;

export type EntityXProductData = z.infer<typeof entityXProductsFormSchema>;

export type ProductsData = z.infer<typeof productFormSchema>;

export type OctaneFooterData = z.infer<typeof octaneFooterSchema>;

export type HeroBannerData = z.infer<typeof heroBannerFormSchema>;
export type MiniBannerData = z.infer<typeof miniBannerFormSchema>;
export type HeroBannerItem = z.infer<typeof heroBannerItemSchema>;
export type TimerSchema = z.infer<typeof timerSchema>;

export type FilterSchema = z.infer<typeof filterSchema>;

export const NON_ARRAY_FILTERS = [
	'price.from',
	'price.to',
	'price.upto',
	'sort',
];

export const ARRAY_FILTERS_VALUES = [
	'size',
	'brand',
	'discount',
	'node_id',
	'delivery_days',
	'tags',
	'attrs',
] as const;

export type ARRAY_FILTERS_TYPES = (typeof ARRAY_FILTERS_VALUES)[number];
