<script setup lang="ts">
import { Button, IconButton } from '@laam/ui/base';

import { PhFunnel, PhSlidersHorizontal } from '@phosphor-icons/vue';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';
import type { PlpUiExp } from '~/types/statsig.ts';

const plpNewUi = inject('plpNewUi') as Ref<PlpUiExp>;
const filterCount = inject('filterCount') as Ref<number>;

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();
</script>
<template class="size_filter_sheet">
	<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
		<IconButton
			v-if="plpNewUi.allowed"
			:size="'xs'"
			variant="secondary"
			rounded="full"
		>
			<PhSlidersHorizontal />
		</IconButton>
		<Button v-else :size="'xs'" :variant="'tertiary'">
			<PhFunnel :weight="'bold'" />
			{{ 'Filter ' + (filterCount ? `(${filterCount})` : '') }}
		</Button>
	</FilterDrawer>
	<FilterDialog v-else @reset-form="() => emits('reset-form')">
		<IconButton
			v-if="plpNewUi.allowed"
			rounded="full"
			variant="secondary"
			size="nav-button"
		>
			<PhSlidersHorizontal />
		</IconButton>
		<Button v-else :size="'md'" :variant="'secondary'">
			<PhFunnel :weight="'bold'" />
			<span class="px-xxs">
				{{ 'Filter ' + (filterCount ? `(${filterCount})` : '') }}
			</span>
		</Button>
	</FilterDialog>
</template>
