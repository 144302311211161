import {
	type FilterSchema,
	type FiltersResponseSchema,
} from '@laam/cms-shared';
import {
	PriceFilterForm,
	SizeFilterForm,
	DiscountFilterForm,
	DeliveryFilterForm,
	StyleFilterForm,
	BrandFilterForm,
	FabricFilterForm,
	ColorFilterForm,
	SeasonFilterForm,
	NoOfPiecesFilterForm,
	WorkTechniqueFilterForm,
	OccasionFilterForm,
} from '@laam/ui';
import SalePercentageFilterForm from './forms/SalePercentageFilterForm.vue';
import type { FieldContext } from 'vee-validate';

export const activeFilterStyle =
	/* css */ 'border-gray-800 bg-gray-50 color-gray-800 [&_svg]:fill-gray-800';

export const removeEmptyFilters = (
	filters: FilterSchema,
	filterList: FiltersResponseSchema,
) => {
	const newFilters = { ...filters };
	for (const key in newFilters) {
		const val = newFilters[key as keyof FilterSchema];
		if (val) {
			if (Array.isArray(val) && val.length === 0) {
				delete newFilters[key as keyof FilterSchema];
			}
			if (
				key === 'price' &&
				(val as FilterSchema['price'])!.from === filterList.prices!.min_price &&
				(val as FilterSchema['price'])!.to === filterList.prices!.max_price
			) {
				delete newFilters.price;
			}
		}
	}
	return newFilters;
};

export const SortValueToLabelMap = {
	RECOMMENDED: 'Recommended',
	BEST_SELLING: 'Best Selling',
	DATE_NEW_TO_OLD: 'Newest First',
	PRICE_LOW_TO_HIGH: 'Price: Low to High',
	PRICE_HIGH_TO_LOW: 'Price: High to Low',
	DISCOUNT_HIGH_TO_LOW: 'Discount: High to Low',
	DISCOUNT_LOW_TO_HIGH: 'Discount: Low to High',
	DELIVERY_EARLIEST_TO_LATEST: 'Delivery: Earliest to Latest',
	DELIVERY_LATEST_TO_EARLIEST: 'Delivery: Latest to Earliest',
};

export const filterFormsMap: Record<
	keyof FiltersResponseSchema,
	{
		label: string;
		form: Component;
		newFilterCheck?: boolean;
		discountExpCheck?: boolean;
	}
> = {
	fabrics: {
		label: 'Fabric',
		form: FabricFilterForm,
		newFilterCheck: true,
	},
	prices: {
		label: 'Price',
		form: PriceFilterForm,
	},
	size: {
		label: 'Size',
		form: SizeFilterForm,
	},
	'attrs.color-type': {
		label: 'Color',
		form: ColorFilterForm,
		newFilterCheck: true,
	},
	nodes: {
		label: 'Styles',
		form: StyleFilterForm,
	},
	brands: {
		label: 'Brands',
		form: BrandFilterForm,
	},
	'attrs.season': {
		label: 'Season',
		form: SeasonFilterForm,
		newFilterCheck: true,
	},
	'attrs.product-type': {
		label: 'Occasion',
		form: OccasionFilterForm,
	},
	discounts: {
		label: 'Discount',
		form: DiscountFilterForm,
		discountExpCheck: false,
	},
	sale_percentage: {
		label: 'Discount',
		form: SalePercentageFilterForm,
		discountExpCheck: true,
	},
	delivery_days: {
		label: 'Delivery',
		form: DeliveryFilterForm,
	},
	'attrs.number-of-pieces': {
		label: 'No of Pieces',
		form: NoOfPiecesFilterForm,
		newFilterCheck: true,
	},
	'attrs.work-technique': {
		label: 'Work Technique',
		form: WorkTechniqueFilterForm,
		newFilterCheck: true,
	},
};

export const keyMapping = {
	price: 'prices',
	brand: 'brands',
	discount: 'discounts',
	node_id: 'nodes',
	'attrs.fabric': 'fabrics',
	'attrs.fabric-shirt': 'fabrics',
	'attrs.bottom-fabric': 'fabrics',
	'attrs.dupatta-fabric': 'fabrics',
	'attrs.fabric painting': 'fabrics',
	'attrs.poly fabric': 'fabrics',
	'attrs.shirt-fabrics': 'fabrics',
	'attrs.trouser-fabrics': 'fabrics',
};

export const transformToFilterResponse = (
	data: Record<string, any>,
	mapping: Record<string, string>,
): Record<string, any> => {
	const transformedData: Record<string, any> = {};

	for (const [key, value] of Object.entries(data)) {
		const backendKey = mapping[key] || key;
		transformedData[backendKey] = value;
	}

	return transformedData;
};

export const calculateFiltersCount = (
	appliedFilters: Record<string, any>,
	attrsField: FieldContext<string[] | undefined>,
	filters: Record<string, any>,
): Record<string, number> => {
	const keys = Object.keys(appliedFilters || {}).filter(
		(key) => key !== 'search',
	);

	const appliedAttributes = Object.values(attrsField.value.value || {})
		.map((value) => value?.split('.')?.[0])
		.filter((key): key is string => key !== undefined)
		.reduce<Record<string, number>>((prev, curr) => {
			prev[`attrs.${curr}`] = (prev[`attrs.${curr}`] || 0) + 1;
			return prev;
		}, {});

	const countsByKey: Record<string, number> = {};

	for (const key of keys) {
		const val = appliedFilters[key];

		if (key === 'sort') {
			continue;
		} else if (key === 'price' && val) {
			const price = val as (typeof filters)['prices'];
			if (
				price?.from !== filters?.prices?.min_price ||
				price?.to !== filters?.prices?.max_price
			) {
				countsByKey[key] = 1;
			} else {
				countsByKey[key] = 0;
			}
		} else if (key === 'sale_percentage' && val) {
			countsByKey[key] = 1;
		} else {
			if (val && Array.isArray(val)) {
				countsByKey[key] = val.length;
			} else {
				countsByKey[key] = 0;
			}
		}
	}

	return transformToFilterResponse(
		{ ...countsByKey, ...appliedAttributes },
		keyMapping,
	);
};
