<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { Slider } from '@laam/ui/base';
import { useDebounceFn } from '@vueuse/core';
import { useField } from 'vee-validate';
import { computed, inject, onMounted, type Ref } from 'vue';
import { TextInput } from '@laam/ui/base';

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: {
				toPrice: number;
				fromPrice: number;
			};
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const fieldPriceMin = useField<number>('price.from');
const fieldPriceMax = useField<number>('price.to');

const MIN_PRICE = filters.value.prices!.min_price;
const MAX_PRICE = filters.value.prices!.max_price;

onMounted(() => {
	if (!fieldPriceMin.value.value || !fieldPriceMax.value.value) {
		fieldPriceMin.setValue(MIN_PRICE);
		fieldPriceMax.setValue(MAX_PRICE);
	}
});

const debouncedFormUpdate = useDebounceFn((min: number, max: number) => {
	fieldPriceMin.setValue(min);
	fieldPriceMax.setValue(max);
}, 10);

const debouncedEmit = useDebounceFn(() => {
	emit('update-filter', {
		filterType: 'price',
		filterValue: {
			fromPrice: fieldPriceMin.value.value,
			toPrice: fieldPriceMax.value.value,
		},
		source: window.location.href,
	});
}, 1000);

const isPriceFilterInitialized = computed(
	() => fieldPriceMin.value.value && fieldPriceMax.value.value,
);

const handleChange = (value: number[] | undefined) => {
	if (value && value.length === 2) {
		debouncedFormUpdate(value[0]!, value[1]!);
		debouncedEmit();
	}
};
</script>
<template>
	<div class="flex flex-col price_filter_form gap-3xl">
		<div class="flex w-full gap-3xl">
			<div class="flex flex-col overflow-hidden gap-sm grow">
				<TextInput
					v-model.number="fieldPriceMin.value.value"
					type="number"
					field-name="price.from"
					place-holder="From"
					label="From"
				/>
			</div>

			<div class="flex flex-col overflow-hidden gap-sm grow">
				<TextInput
					v-model.number="fieldPriceMax.value.value"
					type="number"
					field-name="price.to"
					place-holder="To"
					label="To"
				/>
			</div>
		</div>
		<Slider
			:min="MIN_PRICE"
			:max="MAX_PRICE"
			:step="500"
			:model-value="
				isPriceFilterInitialized
					? [fieldPriceMin.value.value, fieldPriceMax.value.value]
					: [MIN_PRICE, MAX_PRICE]
			"
			@update:model-value="handleChange"
		/>
	</div>
</template>
