<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import { activeFilterStyle } from './utils';
import { useField } from 'vee-validate';
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { AppliedFilters, StyleFilterForm } from '@laam/ui';

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;
const setFiltersFromUrl = inject<() => void>('setFiltersFromUrl');

const show = computed(() => !!filters.value?.nodes?.length);
const drawerToggle = ref(false);
const toggleDropdown = () => {
	drawerToggle.value = !drawerToggle.value;
};

const field = useField<string[] | undefined>('node_id');
const filterCount = computed(() => field.value.value?.length || 0);

const resetField = () => {
	toggleDropdown();
	field.setValue([]);
};
</script>
<template class="style_filter_sheet">
	<div v-if="show">
		<Drawer v-if="isMobileView()">
			<DrawerTrigger :as-child="true">
				<Button
					size="xs"
					variant="secondary"
					class="style_filter_sheet_trigger"
					:class="filterCount ? activeFilterStyle : ''"
				>
					{{ 'Styles ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					<PhCaretDown :weight="'bold'" />
				</Button>
			</DrawerTrigger>
			<DrawerContent
				side="bottom"
				class="style_filter_sheet_content"
				@pointer-down-outside="setFiltersFromUrl"
			>
				<DrawerHeader>
					<DrawerTitle>Styles</DrawerTitle>
				</DrawerHeader>
				<h1
					v-if="filterCount > 0"
					class="font-semibold text-gray-800 text-md p-7xl"
				>
					Selected Filters
				</h1>
				<AppliedFilters :filter-applied="'node_id'" class="p-7xl pt-0" />
				<div>
					<StyleFilterForm />
				</div>
				<DrawerFooter>
					<DrawerTrigger :as-child="true">
						<Button
							size="lg"
							variant="primary"
							class="grow"
							type="submit"
							form="filterForm"
						>
							{{ 'Apply ' + (filterCount > 0 ? `(${filterCount})` : '') }}
						</Button>
					</DrawerTrigger>
					<DrawerTrigger :as-child="true">
						<Button
							size="lg"
							variant="secondary"
							form="filterForm"
							type="submit"
							@click="resetField"
						>
							Reset
						</Button>
					</DrawerTrigger>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>

		<DropdownMenu v-else v-model:open="drawerToggle">
			<DropdownMenuTrigger as-child>
				<Button
					size="md"
					variant="secondary"
					class="style_filter_sheet_trigger"
					:class="filterCount ? activeFilterStyle : ''"
				>
					{{ 'Styles ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					<PhCaretDown :weight="'bold'" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				class="w-[432px] !max-h-[570px] bg-white"
				@pointer-down-outside="setFiltersFromUrl"
			>
				<DropdownMenuLabel
					class="px-3xl py-3xl font-gray-800 text-xl font-semibold text-center"
				>
					Styles
				</DropdownMenuLabel>

				<DropdownMenuSeparator />
				<h1
					v-if="filterCount > 0"
					class="font-semibold text-gray-800 text-md p-3xl"
				>
					Selected Filters
				</h1>
				<AppliedFilters :filter-applied="'node_id'" class="p-3xl pt-0" />
				<DropdownMenuSeparator v-if="filterCount > 0" />

				<StyleFilterForm class="p-7xl" />

				<DropdownMenuSeparator />

				<div
					class="px-3xl py-3xl flex items-center gap-md sticky bottom-0 w-full bg-white"
				>
					<Button
						size="lg"
						variant="primary"
						class="grow bg-gray-800 w-full"
						type="submit"
						form="filterForm"
						@click="toggleDropdown()"
					>
						{{ 'Apply ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					</Button>
					<Button
						size="lg"
						variant="secondary"
						class="discount_filter_reset w-full"
						type="submit"
						form="filterForm"
						@click="resetField"
					>
						Reset
					</Button>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	</div>
</template>
