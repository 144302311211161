<script setup lang="ts">
import { TabsTrigger } from 'radix-vue';
import { Tabs, TabsList, TabsContent } from '@laam/ui/base';
import type { FilterSchema, FiltersResponseSchema } from '@laam/cms-shared';
import { AppliedFilters } from '@laam/ui';
import { useField, useFormValues } from 'vee-validate';
import { filterFormsMap, calculateFiltersCount } from '.././utils';
import SortFilterForm from './SortFilterForm.vue';
import { hasCategories } from '~/data/filters';
import { isMobileView } from '~/utils/helpers';

const filterCount = inject('filterCount') as Ref<number>;
const filters = inject('filters') as Ref<FiltersResponseSchema>;
const newFiltersEnabled = inject('newFiltersEnabled') as Ref<Boolean>;
const selectedFilter = inject('selectedFilter', ref('sort')) as Ref<string>;
const discountExpCheck = inject('discountExpCheck') as Ref<Boolean>;

const appliedFilters = useFormValues<FilterSchema>();
const attrsField = useField<string[] | undefined>('attrs');

// Computed property to determine which filters to show
const filterKeys = computed(() => {
	return Object.keys(filterFormsMap).filter((key) => {
		let shouldReturn = true; // Initialize a boolean to determine if the key should be included
		const filterValue = filters.value[key as keyof FiltersResponseSchema];
		const filterConfig = filterFormsMap[key as keyof typeof filterFormsMap];

		// Special case: Always include the 'prices' filter if it exists in the filters object
		if (
			filters.value &&
			Object.keys(filters.value).includes('prices') &&
			key === 'prices'
		) {
			return true;
		}

		// Handle the experiment toggle for discounts and sale percentage
		if (filterConfig.discountExpCheck !== undefined) {
			if (discountExpCheck.value && !filterConfig.discountExpCheck) {
				return false; // Exclude discounts if in discount value experiment
			}
			if (!discountExpCheck.value && filterConfig.discountExpCheck) {
				return false; // Exclude sale_percentage if in discount value experiment
			}
		}

		// If new filters are not enabled, exclude keys marked with newFilterCheck
		if (filterConfig.newFilterCheck !== undefined) {
			if (!newFiltersEnabled.value && filterConfig.newFilterCheck) {
				shouldReturn = false;
			}
		}

		// Exclude keys that do not exist in the filters object
		if (!filters.value || !Object.keys(filters.value).includes(key)) {
			return false;
		}

		// Handle cases where the filter value is an array
		if (Array.isArray(filterValue)) {
			return shouldReturn && filterValue.length > 0;
		}
		// Handle cases where the filter value is an object
		else if (filterValue && typeof filterValue === 'object') {
			return shouldReturn && hasCategories(filterValue);
		}

		// Default case: exclude the key if none of the above conditions are met
		return false;
	});
});

const filterCountsByKey = computed(() => {
	return calculateFiltersCount(appliedFilters.value, attrsField, filters.value);
});
</script>

<template>
	<div
		class="flex flex-col h-[calc(100vh-275px)] md:h-[calc(100vh-400px)] w-full all_filter_form"
	>
		<div
			v-if="filterCount > 0"
			class="pl-lg md:pl-3xl py-xl border-b border-gray-200"
		>
			<AppliedFilters class="max-h-11xl overflow-y-scroll" />
		</div>

		<Tabs
			class="flex flex-row overflow-y-scroll h-full"
			orientation="horizontal"
			:default-value="selectedFilter"
		>
			<TabsList
				class="flex flex-col items-start flex-[3.5] md:flex-[2] border-r border-gray-200 overflow-y-scroll"
				orientation="vertical"
			>
				<TabsTrigger
					value="sort"
					class="w-full text-start font-semibold text-xs h-10xl px-lg py-3xl md:p-3xl border-b border-gray-200 hover:text-gray text-gray-500 data-[state=active]:text-white data-[state=active]:bg-gray-900"
				>
					Sort By
				</TabsTrigger>
				<TabsTrigger
					v-for="filterType in filterKeys"
					:key="filterType"
					:value="filterType"
					class="w-full text-start font-semibold text-xs h-10xl px-lg py-3xl md:p-3xl border-b border-gray-200 hover:text-gray text-gray-500 data-[state=active]:text-white data-[state=active]:bg-gray-900"
				>
					{{
						filterFormsMap[filterType as keyof typeof filterFormsMap].label +
						(filterCountsByKey[filterType]
							? ` (${filterCountsByKey[filterType]})`
							: '')
					}}
				</TabsTrigger>
			</TabsList>
			<TabsContent
				class="p-3xl flex-[6.5] md:flex-[8] overflow-y-scroll"
				value="sort"
			>
				<div class="pb-3xl text-sm font-semibold text-gray-800">Sort By</div>
				<SortFilterForm />
			</TabsContent>
			<TabsContent
				v-for="filterType in filterKeys"
				:key="filterType"
				:value="filterType"
				class="p-3xl flex-[6.5] md:flex-[8] overflow-y-scroll"
			>
				<div class="pb-3xl text-sm font-semibold text-gray-800">
					{{ filterFormsMap[filterType as keyof typeof filterFormsMap].label }}
				</div>
				<component
					:is="filterFormsMap[filterType as keyof typeof filterFormsMap].form"
					:is-mobile-view="isMobileView()"
				/>
			</TabsContent>
		</Tabs>
	</div>
</template>
