<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	DrawerDescription,
} from '@laam/ui/base';
import { PhFunnelSimple } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';
import { SortValueToLabelMap } from './utils';
import type { FilterSchema } from '@laam/cms-shared';
import { SortFilterForm } from '@laam/ui';
import { isMobileView } from '~/utils/helpers';
import type { PlpUiExp } from '~/types/statsig.ts';
import SortFilterFormExp from '~/components/filter-bar/SortFilterFormExp.vue';

const setFiltersFromUrl = inject<() => void>('setFiltersFromUrl');

const field = useField<FilterSchema['sort_by']>('sort_by');
const plpNewUi = inject('plpNewUi') as Ref<PlpUiExp>;

const drawerToggle = ref(false);
const toggleDropdown = () => {
	drawerToggle.value = !drawerToggle.value;
};

function resetField() {
	toggleDropdown();
	field.setValue(undefined);
}
</script>
<template>
	<Drawer v-if="isMobileView()" class="sort_filter_drawer">
		<DrawerTrigger :as-child="true">
			<Button size="xs" variant="tertiary" class="sort_filter_drawer_trigger">
				<template #leftIcon>
					<PhFunnelSimple :weight="isMobileView() ? 'bold' : 'regular'" />
				</template>
				<span class="lg:px-xxs">
					{{
						field.value.value
							? SortValueToLabelMap[
									field.value.value as keyof typeof SortValueToLabelMap
								]
							: 'Sort'
					}}
				</span>
			</Button>
		</DrawerTrigger>
		<DrawerContent
			side="bottom"
			class="sort_filter_drawer_content"
			@pointer-down-outside="setFiltersFromUrl"
		>
			<DrawerHeader>
				<DrawerTitle> Sort </DrawerTitle>
			</DrawerHeader>
			<DrawerDescription class="p-3xl">
				<SortFilterFormExp v-if="plpNewUi.allowed" />
				<SortFilterForm v-else />
			</DrawerDescription>
			<DrawerFooter>
				<DrawerTrigger :as-child="true">
					<Button
						size="lg"
						variant="primary"
						class="grow sort_filter_apply"
						type="submit"
						form="filterForm"
					>
						Apply
					</Button>
				</DrawerTrigger>
				<DrawerTrigger :as-child="true">
					<Button size="lg" variant="secondary" @click="resetField">
						Reset
					</Button>
				</DrawerTrigger>
			</DrawerFooter>
		</DrawerContent>
	</Drawer>

	<DropdownMenu v-else v-model:open="drawerToggle">
		<DropdownMenuTrigger :as-child="true">
			<Button
				size="md"
				variant="secondary"
				class="sort_filter_dropdown_trigger w-fit"
			>
				<template #leftIcon>
					<PhFunnelSimple :weight="'regular'" />
				</template>
				<span class="lg:px-xxs">
					{{
						field.value.value
							? SortValueToLabelMap[
									field.value.value as keyof typeof SortValueToLabelMap
								]
							: 'Sort'
					}}
				</span>
			</Button>
		</DropdownMenuTrigger>
		<DropdownMenuContent
			class="bg-white sort_filter_dropdown_content w-[288px]"
			@pointer-down-outside="setFiltersFromUrl"
		>
			<DropdownMenuLabel
				class="!px-7xl !py-3xl font-gray-800 text-xl font-semibold text-center"
			>
				Sort
			</DropdownMenuLabel>

			<DropdownMenuSeparator />

			<div class="p-3xl">
				<SortFilterFormExp v-if="plpNewUi.allowed" />
				<SortFilterForm v-else />
			</div>

			<DropdownMenuSeparator />

			<div class="px-3xl py-7xl flex items-center gap-md flex-col">
				<Button
					size="lg"
					variant="primary"
					class="grow sort_filter_apply bg-gray-800 w-full"
					type="submit"
					form="filterForm"
					@click="toggleDropdown()"
				>
					Apply
				</Button>
				<Button
					size="lg"
					variant="secondary"
					type="submit"
					form="filterForm"
					class="w-full"
					@click="resetField"
				>
					Reset
				</Button>
			</div>
		</DropdownMenuContent>
	</DropdownMenu>
</template>
